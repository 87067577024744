exports.suburbMap = {
    'Wellington': [
        'Makara',
        'Aro Valley',
        'Berhampore',
        'Breaker Bay',
        'Broadmeadows',
        'Brooklyn',
        'Chartwell',
        'Crawford',
        'Crofton Downs',
        'Evans Bay',
        'Glenside',
        'Grenada',
        'Grenada North',
        'Grenada Village',
        'Hataitai',
        'Horokiwi',
        'Houghton Bay',
        'Island Bay',
        'Johnsonville',
        'Kaiwharawhara',
        'Karaka Bays',
        'Karori',
        'Kelburn',
        'Khandallah',
        'Kilbirnie',
        'Kowhai Park',
        'Lambton',
        'Linden',
        'Lyall Bay',
        'Makara-Ohariu',
        'Maupuia',
        'Melrose',
        'Miramar',
        'Moa Point',
        'Mornington',
        'Mount Cook',
        'Newlands',
        'Newtown',
        'Ngaio',
        'Ngauranga',
        'Northland',
        'Ohariu',
        'Oriental Bay',
        'Owhiro Bay',
        'Paparangi',
        'Pipitea',
        'Raroa',
        'Redwood',
        'Rongotai',
        'Roseneath',
        'Seatoun',
        'Seatoun Heights',
        'Southgate',
        'Tawa',
        'Te Aro',
        'Thorndon',
        'Vogeltown',
        'Wadestown',
        'Wellington Central',
        'Wilton',
        'Woodridge',
        'Churton Park',
        'Strathmore Park',
        'Highbury',
        'Mount Victoria',
        'Kingston',
        'Homebush',
    ],
    'Lower Hutt': [
        'Alicetown',
        'Avalon',
        'Belmont',
        'Boulcott',
        'Days Bay',
        'Eastbourne',
        'Epuni',
        'Fairfield',
        'Gracefield',
        'Harbour View',
        'Haywards',
        'Holborn',
        'Kelson',
        'Korokoro',
        'Lower Hutt',
        'Lowry Bay',
        'Mahina Bay',
        'Manor Park',
        'Maungaraki',
        'Melling',
        'Moera',
        'Muritai',
        'Naenae',
        'Normandale',
        'Park Avenue',
        'Petone',
        'Rona Bay',
        'Seaview',
        'Stokes Valley',
        'Sunshine Bay',
        'Taita',
        'Tirohanga',
        'Wainuiomata',
        'Waiwhetu',
        'Waterloo',
        'Woburn',
        'York Bay',
        'Hutt Central'
    ],
    'Upper Hutt': [
        'Akatarawa',
        'Birchville',
        'Blue Mountains',
        'Brown Owl',
        'Clouston Park',
        'Ebdentown',
        'Elderslea',
        'Heretaunga',
        'Kaitoke',
        'Kingsley Heights',
        'Mangaroa',
        'Maoribank',
        'Maymorn',
        'Moonshine Valley',
        'Mount Marua',
        'Pakuratahi',
        'Pinehaven',
        'Riverstone Terraces',
        'Silverstream',
        'Te Marua',
        'The Plateau',
        'Timberlea',
        'Totara Park',
        'Trentham',
        'Upper Hutt',
        'Wallaceville',
        'Whitemans Valley',
    ],
    'Porirua / Mana': [
        'Aotea',
        'Ascot Park',
        'Camborne',
        'Cannons Creek',
        'Elsdon',
        'Judgeford',
        'Karehana Bay',
        'Kenepuru',
        'Mana',
        'Papakowhai',
        'Paremata',
        'Pauatahanui',
        'Plimmerton',
        'Porirua',
        'Porirua East',
        'Pukerua Bay',
        'Ranui Heights',
        'Takapu Valley',
        'Takapuwahia',
        'Titahi Bay',
        'Waitangirua',
        'Whitby',
        'Ranui'
    ],
    'Kapiti Coast': [
        'Otaihanga',
        'Otaki',
        'Otaki Beach',
        'Paekakariki',
        'Paraparaumu Beach',
        'Peka Peka',
        'Raumati Beach',
        'Raumati South',
        'Te Horo',
        'Waikanae',
        'Waikanae Beach',
        'Paraparaumu',
        'Reikorangi',
        'Te Horo Beach'
    ],
    'Wairarapa': [
        'Carterton',
        'Castlepoint',
        'Flat Point',
        'Masterton',
        'Mataikona',
        'Mauriceville',
        'Riversdale Beach',
        'Cape Palliser',
        'Featherston',
        'Gladstone',
        'Greytown',
        'Lake Ferry',
        'Martinborough',
        'Ngawi',
        'Ocean Beach',
        'Tora',
    ],
}