import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce, filter } from 'lodash'
import { useFormik } from 'formik'
import axios from 'axios'
import qs from 'querystring'
import Form from './components/Form/Form';
import './App.css';
import PropertyList from './components/PropertyList/PropertyList';
import { bedroomOptions } from './constants/bedroomsBreakpoints';
import { priceOptions } from './constants/priceBreakpoints';
import { propertyTypeOptions } from './constants/propertyTypes';
import AdditionalFilters from './components/AdditionalFilters/AdditionalFilters';
import { sortingOptions } from './constants/sortingOptions';


const AppContainer = styled.div`
  width: 100vw;
  /* padding: 30px 15px; */
  /* margin: 0px auto; */

`

const pageQuery = qs.parse(window.location.search.replace('?', ''))
const pageQueryOptions = {}

Object.keys(pageQuery).forEach(key => {


  if (['search', 'status'].includes(key)) {
    pageQueryOptions[key] = pageQuery[key]
    return
  }

  if (['bathrooms', 'bedrooms', 'parkings'].includes(key)) {
    pageQueryOptions[key] = bedroomOptions.find(({ value }) => value === +pageQuery[key])
    return
  }

  if (key === 'openHomes') {
    pageQueryOptions[key] = pageQuery[key] === 'true'
    return
  }

  if (['priceMax', 'priceMin'].includes(key)) {
    pageQueryOptions[key] = priceOptions.find(({ value }) => value === +pageQuery[key])
    return
  }

  if (key === 'propertyType') {
    const propertyTypes = pageQuery[key].split(',')
    pageQueryOptions[key] = propertyTypes.map(t => propertyTypeOptions.find(({ value }) => t === value))
    return
  }

  if (key === 'sort') {
    pageQueryOptions[key] = sortingOptions.find(({ value }) => pageQuery[key] === value)
    return
  }
  if (key === 'suburb') {
    const suburbs = pageQuery[key].split(',')
    pageQueryOptions[key] = suburbs.map(s => ({
      label: s,
      value: s
    }))
    return
  }


  pageQueryOptions[key] = {
    label: pageQuery[key],
    value: pageQuery[key]
  }
})




function App({ settings }) {
  if (!settings) {
    settings = {
      show_properties: true
    }
  }

  const [properties, setProperties] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getFiltersQueryString = (filters) => {
    const usedFilters = Object.keys(filters)
      .filter(k => Array.isArray(filters[k]) ? filters[k].length > 0 : !!filters[k])
      .reduce((newFilters, filterKey) => {

        newFilters[filterKey] = 
          Array.isArray(filters[filterKey]) ? 
            filters[filterKey]
              .filter(v => v)
              .filter(({ value }) => value !== 'select-all')
              .map(({ value }) => value).join(',') 
            : filters[filterKey].value ?? filters[filterKey]
        return newFilters
      }, {})
    return qs.stringify(usedFilters)
  }

  const getProperties = async (filters) => {

    const query = getFiltersQueryString(filters)
    // const propertiesData = await axios.get(`https://tommys-filter.disrupted.co.nz/api/properties?${query}`, {
    const propertiesData = await axios.get(`https://tommys-api-dev.disrupted.co.nz/api/properties?${query}`, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
    setProperties(propertiesData.data)
    setIsLoading(false)
  }


  const {
    handleChange,
    setFieldValue,
    handleSubmit,
    values,
    dirty
  } = useFormik({
    initialValues: {
      search: pageQueryOptions.search || '',
      city: pageQueryOptions.city || null,
      suburb: pageQueryOptions.suburb || [],
      propertyType: pageQueryOptions.propertyType || [],
      priceMin: pageQueryOptions.priceMin || null,
      priceMax: pageQueryOptions.priceMax || null,
      bathrooms: pageQueryOptions.bathrooms || null,
      bedrooms: pageQueryOptions.bedrooms || null,
      parkings: pageQueryOptions.parkings || null,
      openHomes: pageQueryOptions.openHomes || false,
      status: pageQueryOptions.status || 'current',
      sort: pageQueryOptions.sort || null
    },

    onSubmit: async (values) => {
      if (settings?.show_properties) {
        return await getProperties(values)
      }
      const query = getFiltersQueryString(values)
      // window.location.href = `https://www.tommys.co.nz/${settings.redirect_url}${query ? `?${query}` : ''}`;
      window.location.href = `/${settings.redirect_url}${query ? `?${query}` : ''}`;
    }
  })

  useEffect(() => {

    if (!settings.show_properties) return

    const query = getFiltersQueryString(values)
    let attempts = 0;
    while(true){
      try{

        if (attempts === 3) break;

        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query}`;
        window.history.replaceState({ path: newUrl }, '', newUrl);
        break;
      }catch(e){
        attempts++;
      }
    }

  }, [values])

  const updateProperties = useCallback(debounce(async (values) => {
    if (!settings?.show_properties) return
    await getProperties(values)
  }, 500), [])

  useEffect(() => {
    // if (!dirty) return
    updateProperties(values)
  }, [values])

  return (
    <AppContainer className="tommys-filter">

      <Form {...{ handleChange, setFieldValue, values, handleSubmit, settings }} />
      {settings?.show_properties && (
        <>
          <AdditionalFilters {...{ values, setFieldValue }} />
          <PropertyList {...{ properties, isLoading }} />
        </>
      )
      } 

    </AppContainer>
  );
}

export default App;
