import * as dateFns from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import bathIcon from "../../../images/bath.png";
import bedIcon from "../../../images/bed.png";
import carIcon from "../../../images/car.png";
import imageIcon from "../../../images/image_gallery_icon.png";
import locationIcon from "../../../images/location_icon.png";
import openSpaceIcon from "../../../images/openspace.png";

const PropertyContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 0 3px #ccc;

  color: #666666;
`;

const PropertyHeader = styled.a`
  position: relative;
  display: block;

  & > img {
    width: 100%;
    height: 220px;
  }
`;

const PropertyImage = styled.img`
  object-fit: cover;
`;

const PropertyBadges = styled.div`
  color: white;
  display: flex;
  position: absolute;
  top: 20px;
  left: 10px;
`;

const AgentOpenHomeContainer = styled.div`
  margin: 5px 0;

  & > div:nth-child(2) {
    margin: 5px 0;
  }
`;

const JustListed = styled.div`
  padding: 5px 15px;
  background-color: #0066cc;
`;

const OpenHome = styled.div`
  background: #43b649;
  padding: 5px 15px;
`;

const Sold = styled.div`
  background: #ec008c;
  padding: 5px 15px;
`;

const ImageCountContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 5px 10px;
  align-items: center;
  color: white;
  position: absolute;
  bottom: 20px;
  left: 10px;
  line-height: 1;

  img {
    max-width: 26px;
    margin-right: 5px;
  }
`;

const Heading = styled.a`
  color: #43b649;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    color: #5b902f;
  }
`;

const DescriptionContainer = styled.div`
  padding: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d3d3d3;
`;

const PropertyAddress = styled.div`
  font-weight: 700;
  color: #666666;
`;

const PropertySuburbContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  img {
    max-width: 25px;
    margin-right: 5px;
  }
`;

const PropertyCharacteristics = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 20px;
  }
`;

const PropertyFooter = styled.div`
  padding: 10px;
`;

const AgentImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border-bottom: 1px solid #d3d3d3;
  margin-right: 5px;
`;

const AgentsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const AgentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PropertyFutureContainer = styled.div`
  display: flex;
  flex-direction: center;
`;

const PropertyFutureImage = styled.img`
  max-height: 20px;
  margin-right: 5px;
`;

const Property = ({ property }) => {
  const address = `${
    property.address.unitNumber ? `${property.address.unitNumber}/` : ""
  }${property.address.streetNumber} ${property.address.street}`;

  const openHomes =
    property.openHomes
      ?.filter((o) => {
        const endDate = dateFns.parseISO(o.end);
        const currentDate = dateFns.parseISO(new Date().toISOString());

        return dateFns.compareAsc(endDate, currentDate) !== -1;
      })
      .map((o) => {
        const startDate = utcToZonedTime(
          dateFns.parseISO(o.start),
          "Pacific/Auckland"
        );
        const endDate = utcToZonedTime(
          dateFns.parseISO(o.end),
          "Pacific/Auckland"
        );
        return `${dateFns.format(
          startDate,
          "EEE do MMM | hh:mm aaaaa'm'"
        )} - ${dateFns.format(endDate, "hh:mm aaaaa'm'")}`;
      }) || [];

  const daysListed = dateFns.differenceInDays(
    new Date(),
    dateFns.parseISO(property.createdAt)
  );
  const propertyUrl = `https://tommysdev.disrupted.co.nz/${property.referenceID}`;

  const isForSale = ["listing", "conditional"].includes(property.status);
  return (
    <PropertyContainer>
      <PropertyHeader href={propertyUrl}>
        {/* <PropertyImage
          src={
            (property.photos &&
              property.photos[0] &&
              property.photos[0].thumb180) ||
            "/wp-content/themes/flatsome-child/img/Placeholder.jpg"
          }
        /> */}
        <LazyLoadImage
          css={{ objectFit: "cover" }}
          src={
            (property.photos &&
              property.photos[0] &&
              property.photos[0]?.thumb1024) ||
            "/wp-content/themes/flatsome-child/img/Placeholder.jpg"
          }
          PlaceholderSrc={
            property.photos[0]?.thumb180 ||
            "/wp-content/themes/flatsome-child/img/Placeholder.jpg"
          }
        />
        <PropertyBadges>
          {daysListed < 10 && <JustListed>Just Listed</JustListed>}
          {openHomes.length > 0 && <OpenHome>Open Home</OpenHome>}
          {!isForSale && <Sold>Sold</Sold>}
        </PropertyBadges>
        <ImageCountContainer>
          <img src={imageIcon} /> {property.photos.length}
        </ImageCountContainer>
      </PropertyHeader>
      <DescriptionContainer>
        <Heading href={propertyUrl}>{property.heading}</Heading>
        <PropertyAddress>{address}</PropertyAddress>
        <PropertySuburbContainer>
          <img src={locationIcon} />
          {property.address.suburb.name} / {property.city}
        </PropertySuburbContainer>
        <PropertyCharacteristics>
          &nbsp;
          {property.bed > 0 && (
            <PropertyFutureContainer>
              <PropertyFutureImage src={bedIcon} /> <b>{property.bed}</b>
            </PropertyFutureContainer>
          )}
          {property.bath > 0 && (
            <PropertyFutureContainer>
              <PropertyFutureImage src={bathIcon} /> <b>{property.bath}</b>
            </PropertyFutureContainer>
          )}
          {property.garages > 0 && (
            <PropertyFutureContainer>
              <PropertyFutureImage src={carIcon} /> <b>{property.garages}</b>
            </PropertyFutureContainer>
          )}
          {property.openSpaces > 0 && (
            <PropertyFutureContainer>
              <PropertyFutureImage src={openSpaceIcon} />
              <b>{property.openSpaces}</b>
            </PropertyFutureContainer>
          )}
        </PropertyCharacteristics>
      </DescriptionContainer>
      <PropertyFooter>
        <AgentOpenHomeContainer>
          {isForSale && <b>Open Home</b>}
          {isForSale && openHomes.length > 0 && <div>{openHomes[0]}</div>}

          {openHomes.length === 0 && isForSale && <div>TBC</div>}
        </AgentOpenHomeContainer>

        <AgentsContainer>
          {property.contactStaff.map((agent) => (
            <AgentContainer key={`${agent.firstName} ${agent.lastName}`}>
              <AgentImage src={agent.photo} />
              <div style={{ paddingRight: "10px" }}>
                {agent.firstName} {agent.lastName}
              </div>
            </AgentContainer>
          ))}
        </AgentsContainer>
      </PropertyFooter>
    </PropertyContainer>
  );
};

export default Property;
