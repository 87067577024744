import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { sortingOptions } from '../../constants/sortingOptions'


const Container = styled.div`
  display: flex;
  max-width: 1080px;
  padding: 15px;
  margin: auto;
  position: relative;
  z-index: 10;

  @media (max-width: 576px) {
    flex-direction: column;

    & > *:last-child{
    margin-top: 20px;
    margin-left: 0px;
    }
  }
`

const PropertyStatusButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: ${({ active }) => active ? 16 : 15}px 8px;
  background-color:${({ active }) => active ? '#43B649' : '#fff'};
  border: ${({ active }) => active ? 'none' : '1px solid #ddd'};
  color: ${({ active }) => active ? '#fff' : '#000'};
  min-width: 110px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  font-weight: 700;
  height: 100%;
`

const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    marginLeft: 'auto',
    minWidth: '130px',
    fontSize: '15.5px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
}



const AdditionalFilters = ({ values, setFieldValue }) => {
  return <Container>
    <PropertyStatusButton
      active={values.status === 'current'}
      onClick={() => setFieldValue('status', 'current')}
    >
      CURRENT
    </PropertyStatusButton>
    <PropertyStatusButton
      active={values.status === 'sold'}
      onClick={() => setFieldValue('status', 'sold')}
    >
      SOLD
    </PropertyStatusButton>
    <Select
      inputProps={{ readOnly: true }}
      isSearchable={false}
      onChange={(value) => { setFieldValue('sort', value) }}
      value={values.sort}
      defaultValue={sortingOptions[0]}
      placeholder="Sort"
      styles={selectStyles}
      options={sortingOptions}
    />
  </Container>
}

export default AdditionalFilters
