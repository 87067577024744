// export const propertyTypes = [
//     "House",
//     "Apartment",
//     "Home & Income",
//     "Lifestyle Property",
//     "Unit",
//     "Townhouse",
//     "Section Res",
//     "Lifestyle Section",
//     "Studio",
//     "Retirement Living",
//     "Carpark",
//     "Boat Shed",
//     "Multiple Properties",
//     "Retail",
//     "Office",
//     "Hotel/Motel/Leisure",
//     "Investment Flats",
//     "Industrial",
//     "Warehouse",
//     "Showroom",
//     "Carpark",
//     "Commercial Land",
//     "Industrial Land",
//     "Tourism",
//     "Hotels Motels Lodges B&B",
//     "Contractor",
//     "Distribution",
//     "Franchise",
//     "Hospitality",
//     "Manufacturing",
//     "Professional",
//     "Restaurant & Cafe",
//     "Age Care Facilities",
//     "Retail Food & Beverages",
//     "Retail - Non Food",
//     "Services",
//     "Sporting",
//     "Tourism",
//     "Transport",
//     "Wholesale",
//     "Beauty & Health",
//     "Education & Training",
//     "Leisure & Entertainment",
//     "Dairy & Superette",
//     "Other",
//     "Lifestyle Property",
//     "Lifestyle Section",
//     "Cropping",
//     "Dairy",
//     "Finishing",
//     "Forestry",
//     "Grazing",
//     "Horticulture",
//     "High Country",
//     "Specialist Livestock",
//     "Bare Land",
//     "Viticulture",
//     "Unspecified",
//     "Sheep/Beef",
//     "Other"
// ]
export const propertyTypes = [
  "House",
  "Apartment",
  "Home & Income",
  "Commercial",
  "Commercial Lease",
  "Commercial Development",
  "Lifestyle Property",
  "Unit",
  "Townhouse",
  "Section Res",
  "Lifestyle Section",
  "Studio",
  "Carpark",
];

const customLabelsMap = {
  "Section Res": "Residential Section",
};

//TODO: Residential Section
export const propertyTypeOptions = propertyTypes.map((t) => {
  return { label: customLabelsMap[t] ?? t, value: t };
});
